<template>
  <div class="setpassword content_getresponse">
    <router-link :to="{name : 'integrations'}" class="close_icon">
      <i class="fal fa-times"></i>
    </router-link>

    <div class="setpassowrd_inner container">

      <div class="top_content_getresponse">
        <img class="get_response_img" src="/img/integrations/gist.svg" alt="">
        <h2>Connect Gist to Your {{ getSiteDetails.agency_name }} Account</h2>
        <p>Enter Your Access Token below to connect your Gist account.</p>
      </div>

      <transition name="slide">
        <form class="basic_form clear" action="javascript:;">

          <div class="input_field col-md-12">
            <label>Gist Account name</label>
            <input v-model="name" type="text" placeholder="Gist Account name">
          </div>

          <div class="input_field col-md-12">
            <label for="pass">Gist Access Token</label>
            <input v-model="key" type="text" placeholder="Gist Access Token">
          </div>
          <div class="col-md-12 text-center btn_sec">
            <button :disabled="disable_connect" class="btn---cta btn-blue btn-round"
                    @click.prevent="setConvertfox()">
              <span>Connect</span>
            </button>
          </div>
        </form>
      </transition>

    </div>
  </div>
</template>

<script>
import {
  getConvertFoxConnectURL
} from '@/config/config.js'
import {mapGetters} from 'vuex'
import http from '@/mixins/http-lib'

export default (
  {
    components: {},
    data() {
      return {
        key: '',
        disable_connect: false,
        name: ''
      }
    },
    async created () {
      await this.getUserProfile()
      await this.fetchWhitelabelAppSettings()
    },
    computed: {
      ...mapGetters(['getProfile'])
    },
    methods: {

      setConvertfox() {
        if (!this.key) {
          this.alertMessage('Please enter your Gist API key.', 'error')
          return
        }
        if (!(this.name.trim())) {
          this.alertMessage('Please enter your Gist account name.', 'error')
          return
        }
        this.disable_connect = true

        const integration = this.getIntegrationPayload()
        http.post(getConvertFoxConnectURL, {
          ...integration,
          key: this.key,
          name: this.name
        }).then(
          response => {
            if (response.data.tokenError && response.data.tokenError == true) {
              this.alertMessage(response.data.message, 'error')
              this.disable_connect = false
            } else {
              if (response.data.status) {
                this.alertMessage(response.data.message, 'success')
              } else {
                this.disable_connect = false
                this.alertMessage(response.data.message, 'error')
              }

              this.disable_connect = false
              this.getUserProfile()
              this.$router.push({
                name: 'integrations'
              })
            }
          },
          response => {
            this.disable_connect = false
            this.alertMessage('Unknown error occurred, support has been notified.', 'error')
          })
      }
    }
  }
)
</script>
